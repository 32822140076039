import React, { useEffect, useState } from "react";
import { AnchorButton, Spinner, Elevation, Card } from "@blueprintjs/core";
import icon from "../assets/tango_icon.png"
import firebase from "../config/firebase"
const db = firebase.firestore()

const CompleteStripeOnboarding = (props) => {

    document.title = "Onboarding Complete - Tango";

    return (
        <div>
            <img src={icon} alt="Tango" style={{float:"left", width:"70px", paddingLeft:"25px", paddingTop:"7px"}}/>

            <span style={{paddingTop:"7px",paddingRight:"35px", float:"right", color:"#A3A3A3", fontWeight:"500"}}>Already have an account? <a href="https://hq.runtango.com/signIn/email" style={{color:"#61C554"}}>Login</a></span>
            
            <div style={{paddingTop: "10%", marginLeft:"33%"}}>     
                <Card style={{width:"452px", borderRadius:"10px", padding:"40px", boxShadow: "0px 0px 15px 0px rgba(0,0,0,.15)"}}>
                    <h1 style={{fontSize:"20px"}}>You're All Set!</h1>
                    <p style={{fontWeight:"bold", fontSize:"14px"}}>A Tango rep will be in touch soon with next steps.</p>
                    {/* <p style={{fontWeight:"bold", fontSize:"14px"}}>We've emailed you your login credentials.</p>
                    <p style={{fontSize:"14px"}}>Retrieve them from your email and proceed to login.</p> */}
                    <br/>
                    {/* <div style={{textAlign:"center"}}>
                    <AnchorButton href="https://hq.runtango.com/signIn/email" minimal large style={{color:"white", borderRadius:"5px", width:"387px", backgroundColor:"#61C554", fontWeight:"bold", fontSize:"12px"}}>Proceed to Login</AnchorButton>
                    </div> */}
                    <br/> 
                </Card>

                {/* <h2>Hey {business.owner.firstName}!</h2>
                <h3>Finish creating your Tango account for {business.businessName}.</h3>
                <br/> */}

                {/* {loading &&
                    <Spinner/>
                } */}
            </div>
        </div>
    )
    



}

export default CompleteStripeOnboarding