import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import firebase from "../config/firebase"
const db = firebase.firestore()

const OrderConfirmation = (props) => {
    const orderId = props.match.params.orderId
    console.log("props", props)

    // const orderId = props.location.pathname.split('/order/').pop()

    console.log("orderId", orderId)



    document.title = "Order Confirmation - Tango";

    const [error, setError] = useState(false);
    const [order, setOrder] = useState(null);
    const [business, setBusiness] = useState(null)
    const [account, setAccount] = useState(null)
    const [accountErr, setAccountUrlErr] = useState(null)


    useEffect(() => {

        db.collection("Orders").doc(orderId).onSnapshot(async(doc) => {
            if(!doc.data()){
                setError(true)
                console.log("not a valid order")
                
            }else{

                const businessSnapshot = await db.collection('Businesses').doc( doc.data().businessId).get()
                const businessData = businessSnapshot.data()
                const accountSnapshot = await db.collection('Accounts').doc( businessData.accountId).get()
                const accountData = accountSnapshot.data()


                setOrder(doc.data())
                setBusiness(businessData)
                setAccount(accountData)

                //redirect to account.mobileOrders.url to show the receipt
                if(accountData && accountData.mobileOrders.url){
                    var hostname = accountData.mobileOrders.url;
                    window.location = `https://${hostname}/order/${doc.data().id}`;
                } else{
                    setAccountUrlErr(true)
                }  
            }
        })

    },[])

    return (
            <div style={{padding:"2em", textAlign:"center"}}>
                <h3>
                    Order Confirmation {business &&<span>at {business.businessName}</span> }
                </h3>

                {error &&
                    <div style={{margin:"5em"}}>
                        There was a problem finding your order.
                    </div>
                }

                {account && account.mobileOrders.url &&
                    <div>
                        <p>Redirecting to {account.mobileOrders.url}</p>
                        <br />
                        <Spinner />
                    </div>
                }

                {accountErr && 
                    <div style={{margin:"5em"}}>
                        There was a problem finding your order.
                    </div>
                }
            </div>
    )
}

export default OrderConfirmation