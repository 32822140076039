import icon from "../assets/tango_icon.png"
import image from "../assets/speedFlex.png"
import { AnchorButton} from "@blueprintjs/core";


export default function Landing() {
    return (
        <div>
        <img src={icon} alt="Tango" style={{float:"left", width:"70px", paddingLeft:"25px", paddingTop:"7px"}}/>

        <span style={{paddingTop:"7px",paddingRight:"35px", float:"right", color:"#A3A3A3", fontWeight:"500"}}>Already have an account? <a href="https://hq.runtango.com/signIn/email" style={{color:"#61C554"}}>Login</a></span>
        
        <div style={{paddingTop: "10%", textAlign:"center"}}>     
            <img src={image} alt="TangoProducts" style={{width:"75%"}}/>
            <br/><br/>
            <AnchorButton href={"https://www.runtango.com/"} large intent="primary">Learn More</AnchorButton>
        </div>
        </div>
    )
}