import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "normalize.css"; //required for blueprint js to work with css
import "@blueprintjs/core/lib/css/blueprint.css"; //required for blueprint js to work
import "@blueprintjs/icons/lib/css/blueprint-icons.css"; //required for blueprint icons js to work

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
