import React, { useEffect, useState } from "react";
import axios from "axios"
import { Spinner, Button, AnchorButton} from "@blueprintjs/core";
import firebase from "../config/firebase"
const db = firebase.firestore()

const UpdateStripeOnboarding = (props) => {

    document.title = "Finish Onboarding - Tango";
    const businessId = props.match.params.businessId
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [business, setBusiness] = useState(false);
    const [onboardingLink, setOnboardingLink] = useState("");


    useEffect(() => {
        db.collection("Businesses").doc(businessId).onSnapshot(async(doc) => {
            if(!doc.data()){
                setError(true)
                console.log("not a valid business")
            }else{             
                setBusiness(doc.data())
                fetchOnboardingLink(doc.data())
            }
        })
    },[])

    const fetchOnboardingLink = (business) =>{
        setLoading(true)

        axios({
            method: 'post',
            url: 'https://us-central1-tango-2.cloudfunctions.net/stripeEvents',
            data: {
                type: "newUpdateAccountLink",
                businessId: business.id,
                stripeConnectId: business.stripeConnect.id,
                tangoStripeAPI: (business.location.country === "Canada" || business.location.country === "CA" ) ? "Canada" : "USA"
            }
        })
        .then((response) =>{
            console.log('posted to cloud function', response)
            setOnboardingLink(response.data.url)
            setLoading(false)
        })
        .catch((error)=>{
            console.log("no link", error)
            setLoading(false)
        })
    }


    if(business){
        return (
            <div style={{margin:"1em", padding:"2em"}}>     

                <h2>Hey {business.owner.firstName}!</h2>
                <h3>Finish creating your Tango account for {business.businessName}.</h3>
                <br/>
      
                <h3>Looks we still need some information from you.</h3>
                {loading &&
                    <Spinner/>
                }
                {onboardingLink &&
                    <AnchorButton href={onboardingLink} large intent="primary">Complete my account</AnchorButton>
                }
            </div>
        )
    }

    if(error){
        return (
            <div style={{margin:"5em"}}>Something went wrong </div>
        )
    }
    
    return null

}

export default UpdateStripeOnboarding