import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import firebase from "../config/firebase"
const db = firebase.firestore()

const QR = (props) => {

    const qrId = props.match.params.qrId
    console.log("props", props)
    console.log("QR code", qrId)

    const [error, setError] = useState(false);
    const [qr, setQR] = useState(null);
    const [business, setBusiness] = useState(null)
    const [account, setAccount] = useState(null)

    useEffect(() => {

        db.collection("QRCodes").doc(qrId).onSnapshot(async(doc) => {
            if(!doc.data()){
                setError(true)
                console.log("not a valid qr")
                
            }else{

                const businessSnapshot = await db.collection('Businesses').doc( doc.data().businessId).get() // get business based on qr.businessId
                const businessData = businessSnapshot.data()
                const accountSnapshot = await db.collection('Accounts').doc( businessData.accountId).get() // get account based on business.accountId
                const accountData = accountSnapshot.data()

                setQR(doc.data())
                setBusiness(businessData)
                setAccount(accountData)

                //redirect to account.mobileOrders.url to show the receipt
                if(accountData && accountData.mobileOrders.url){
                    console.log('found account data from qr', accountData, doc.data(), window.location.hostname)
                    console.log('redirect to here -->', accountData.mobileOrders.url + "/table/" + doc.data().id)
                    var hostname = accountData.mobileOrders.url;
                    console.log('new host name', hostname, `https://${hostname}/table/${doc.data().id}`)
                    window.location = `https://${hostname}/table/${doc.data().id}`;
                }     
            }
        })


    },[])

    return (
        <div style={{textAlign: 'center', margin:'6em', color:'black'}}>
            
            <p>QR</p>
            <p>{qrId}</p>

            {account && account.mobileOrders.url &&
                <div>
                    <p>Redirecting to {account.mobileOrders.url}</p>
                    <br />
                    <Spinner />
                </div>
            }

        </div>
    )
}

export default QR
